html,
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow: hidden;
}
